export const QUERY_KEYS = {
    USER: {
        ME: {
            GET: 'USERS_ME_GET'
        }
    },
    TABLES: {
        USER: {
            TABLE_ID: {
                GET: 'TABLES_USER_TABLE_ID_GET'
            },
            GET: 'TABLES_USER_GET'
        }
    },
    ADMIN: {
        TABLES: {
            GET: 'ADMIN_TABLES_GET'
        },
        USER: {
            USER_ID: {
                TABLES: {
                    TABLE_ID: {
                        GET: 'ADMIN_USER_USER_ID_TABLES_TABLE_ID_GET'
                    },
                    GET: 'ADMIN_USER_USER_ID_TABLES_GET'
                }
            },
            LIST: {
                GET: 'ADMIN_USER_LIST_GET'
            }
        }
    },
    PRODUCT: {
        PRODUCT_ID: {
            GET: 'PRODUCT_PRODUCT_ID_GET'
        },
        LIST: {
            GET: 'PRODUCT_LIST_GET'
        }
    }

} as const;

export const MUTATION_KEYS = {
    AUTH: {
        SIGN_UP: {
            POST: 'AUTH_SIGN_UP_POST'
        },
        SIGN_IN: {
            POST: 'AUTH_SIGN_IN_POST'
        },
        LOGOUT: {
            POST: 'AUTH_LOGOUT_POST'
        },
        RESET_PASSWORD: {
            CONFIRM: {
                POST: 'AUTH_RESET_PASSWORD_CONFIRM_POST'
            },
            POST: 'AUTH_RESET_PASSWORD_POST'
        }
    },
    ADMIN: {
        USER: {
            USER_ID: {
                TABLES: {
                    TABLE_ID: {
                        DELETE: 'ADMIN_USER_USER_ID_TABLES_TABLE_ID_DELETE',
                        PUT: 'ADMIN_USER_USER_ID_TABLES_TABLE_ID_PUT'
                    }
                },
                PUT: 'ADMIN_USER_USER_ID_PUT',
                DELETE: 'ADMIN_USER_USER_ID_DELETE'
            },
            POST: 'ADMIN_USER_POST'
        }
    },
    USER: {
        PROFILE: {
            PUT: 'USER_PROFILE_PUT'
        },
        AVATAR: {
            POST: 'USER_AVATAR_POST'
        }
    },
    TABLES: {
        USER: {
            TABLE_ID: {
                DELETE: 'TABLES_USER_TABLE_ID_DELETE'
            }
        },
        TABLE_ID: {
            TYPE: {
                EXPORT: {
                    GET: 'TABLES_TABLE_ID_TYPE_EXPORT_GET'
                }
            },
            PUT: 'TABLES_TABLE_ID_PUT'
        },
        UPDATE_CALCULATION_FIELDS: {
            TABLE_ID: {
                PUT: 'TABLES_UPDATE_CALCULATION_FIELDS_TABLE_ID_PUT'
            }
        },
        POST: 'TABLES_POST'
    },
    PRODUCT: {
        PRODUCT_ID: {
            PUT: 'PRODUCT_PRODUCT_ID_PUT',
            DELETE: 'PRODUCT_PRODUCT_ID_DELETE'
        },
        POST: 'PRODUCT_POST'
    }
} as const;
